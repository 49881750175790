<template>
  <div class="d-flex justify-space-between mb-3">
    <h2 class="py-1">
      {{ title }}
      <span v-if="subText" class="font-weight-regular text--secondary">
        {{ subText }}
      </span>
      <slot name="default"></slot>
    </h2>
    <PrimaryButton
      v-if="!isLoading && canEdit"
      light
      dense
      shrink
      @click.native="handleClick"
    >
      {{ actionLabel }}
      <template v-slot:append:icon>
        <v-icon>{{ actionIcon }}</v-icon>
      </template>
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";

export default {
  components: {
    PrimaryButton
  },
  props: {
    /**
     *  Text for section title
     */
    title: {
      type: String,
      required: true
    },
    /**
     *  Icon for action button
     */
    actionIcon: {
      type: String,
      required: true
    },
    /**
     *  Label for action button
     */
    actionLabel: {
      type: String,
      required: true
    },
    isLoading: Boolean,
    canEdit: Boolean,
    subText: String
  },
  methods: {
    handleClick() {
      this.$emit("onClick");
    }
  }
};
</script>

<style lang="scss" scoped></style>
